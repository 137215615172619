.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
  margin-bottom: 30px;
}

h1 {
  color: #333;
}

.json-beautifier {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-container,
.output-container {
  flex: 1;
}

textarea{
  width: 100%;
  height: 300px;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
}

pre {
  background-color: #f5f5f5;
  white-space: pre-wrap;
  word-wrap: break-word;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  font-weight: bold;
}

.faq {
  margin-top: 40px;
}

details {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

summary {
  font-weight: bold;
  cursor: pointer;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}

.json-beautifier {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.input-container,
.output-container {
  width: 100%;
}
textarea {
  width: 100%;
  /* height: 300px; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  resize: vertical;
}
.output-container {
  max-height: 80vh;
  overflow-y: auto;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.output-container h2 {
  margin: 10px;
}
pre {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-family: monospace;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.seo-content {
  margin-top: 40px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.seo-content h2, .seo-content h3 {
  color: #333;
}
.seo-content ul {
  padding-left: 20px;
}
.seo-content li {
  margin-bottom: 10px;
}